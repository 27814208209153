import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import PoolPhBanner from '../../images/products/MineralSwimV2_BENEFITS_pH_Balancing_1920x1000.jpg'
import Holder from '../../images/image.png'
import { Link } from 'gatsby'
const PoolPh = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Maintaining Optimal Pool pH: Key Benefits for Your Water | MineralSwim</title>
			<meta
				name="description"
				content="Add an automatic pH drive to your Mineral Swim system, which will automate your pool’s pH level, so you don’t need to worry about pH levels and handling chemicals."
			/>
			<meta
				name="keywords"
				content="pool pH, pH drive, pH control"
			/>
			<link rel="canonical" href="https://mineralswim.com/benefits/pool-ph/" />
		</Helmet>
		<main  className="bg-white relative">
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>						
							<div className='hidden lg:flex col-span-6'></div>
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-40 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Automatic pH Balancing</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>	Automatic pH dosing makes maintaining your swimming pool's pH water balance easy for a healthy pool and comfortable bathing all year round.	
										</p>				
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=benefits&article=pool-ph">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>	
									</div>								
									</div>                
							</div>
								</header>
				<div className='top-0 bg-cover bg-right lg:bg-left z-0' style={{
                backgroundImage: `url(${PoolPhBanner})`,
								backgroundAttachment: 'fixed',
								position: 'relative',
								height: '90vh',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<div className='relative -mt-24 md:-mt-24 lg:-mt-40 pt-40 pb-20 bg-white z-10'>
				<section  id="main-content" className="max-w-screen-xl font-display text-base mx-auto mb-20 text-justify md:text-left px-8 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-10">
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Effortless Pool pH Control Systems</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Maintaining the right pool pH level is essential for keeping the water clean, clear, and safe for swimming. The ideal pH range for a pool is between 7.2 and 7.6, but this can be challenging to maintain manually. But don’t worry, you don't have to be a pH genius to make it happen! With the help of automatic pH drive systems, you can say goodbye to unnecessary pool test kits and the headache of manual maintenance and hello to a safe and hassle-free swimming experience. </p>
					</div>
					<img src={Holder} alt='holder' className='w-full h-72 border object-cover rounded-2xl'/>					
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Automate Pool pH: Maytronics pH Drive i-Series</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>One such system is the Maytronics pH Drive i-Series, which is designed to automate your pool's pH level to between 7.2 and 7.6. This system is compatible with all Maytronics water purification systems, including Mineral Swim&trade;, Ozone Swim, and Eco Swim. One of the key benefits of using an automatic pH drive system is that it can help to reduce the need for manual pH adjustments. This can be especially helpful for those who are new to pool maintenance or who find it difficult to maintain the right pool pH level manually. With automated pH systems, pool users can have peace of mind knowing that their pool is always at the perfect pH level for a splish-splashing good time!  </p>
					</div>
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Maintaining Optimal Pool pH with Automation</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>In addition to making pool maintenance a breeze, an automatic pH drive system can also help to lower the pH level in a pool. This is important because high pH levels can cause a range of issues such as scaling, cloudy water, and reduced effectiveness of sanitizers. By lowering the pH level to the optimal range, pool owners can help to prevent these issues and ensure that their pool remains clean, clear, and safe for swimming. </p>
					</div>
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Enhance Mineral Swim&trade; Systems with Automatic pH Control</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>	For those who have a Mineral Swim&trade; system, it is possible to add an automatic pH drive to automate the pool’s pH level. This can be an excellent investment for those who want to ensure that their mineral pool remains in top condition. By automating the pH level, pool owners can help to prevent issues such as scaling or bacterial growth, and ensure that the water remains clean, clear, and healthy. 
                    </p>
					</div>
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Effortless Pool pH Management with <br/>Maytronics pH Drive i-Series</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>	To sum it up, maintaining the right pool pH level is essential for keeping your swimming pool water clean, clear, and safe for swimming. But don't go pulling your hair out trying to do it manually! Let Maytronics take over and do the work for you. With an automatic pH drive system like the Maytronics pH Drive i-Series, pool owners can automate the process and ensure that their pool is always at the optimal pH level for safe and enjoyable swimming. By lowering the pool pH level, pool owners can also prevent issues such as scaling, cloudy water, and reduced effectiveness of sanitizers. So, if you have a Mineral Swim&trade; system, adding an automatic pH drive can be an excellent investment. With the pH level on autopilot, you can sit back, relax, and enjoy your pool with peace of mind to focus on the important things, like perfecting your cannonball!
					</p>
					</div>
				</section>
				<div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:flex-1 font-display text-base font-semibold uppercase">
					<Link
					to="/benefits"
									className="cursor-pointer flex items-center justify-center  w-48 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
								>
									Back to Benefits
								</Link>
						<Link
							to="https://www.maytronics.com.au/shop/?query=S-Drive&brand=pH%2520Drive"
							className="whitespace-nowrap inline-flex items-center justify-center w-48 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Buy Now
						</Link>
					</div>
				</div>
				</main>
	</Layout>
)

export default PoolPh